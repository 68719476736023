import React from 'react';
import './VideoBackground.css';

const VideoBackground = () => {
    return (
        <div className="video-container">
            <video className="video-background" autoPlay loop muted>
                <source src="https://storage.zhinengdonghua.com/background.mp4" type="video/mp4" />
                您的浏览器不支持HTML5视频标签。
            </video>
        </div>
    );
};

export default VideoBackground;