import React from 'react';
import {Card} from 'antd';

function About() {
    return (
        <Card bordered={false}
              title={<p style={{
                  color: 'white',
                  fontSize: '30px',
              }
              }>
                  智能动画

              </p>}
              style={{
                  margin: '10% auto 0',
                  color: 'white',
                  // textAlign: 'center',
                  maxWidth: '800px',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)'
              }}>
            <p style={{fontSize: '18px',}}>智能动画代表了动画制作的未来，通过融合人工智能技术，动画制作不再局限于传统的手工方法，而是进入了一个智能化、高效化和创新化的新阶段。无论是自动生成动画，还是优化和增强现有作品，智能动画都为创作者和观众带来了无限的可能性和惊喜。</p>


        </Card>
    );
}

export default About;