import './App.css';
import VideoBackground from "./components/VideoBackground";
import About from "./components/About";
import Models from "./components/Models";

function App() {
  return (
    <div className="App">

     <VideoBackground />

      <About />

        <Models />

    </div>
  );
}

export default App;
