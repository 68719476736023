import {Card} from "antd";
import React from "react";


function Models() {
    return (
        <Card bordered={false}
              style={{
                  margin: '10px auto 0',
                  color: 'white',
                  // textAlign: 'center',
                  maxWidth: '800px',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)'
              }}>
            <p style={{fontSize: '18px'}}>我们致力于探索和发展智能动画技术，通过深度学习算法和先进的计算机视觉技术，实现动画制作流程的自动化和智能化。我们的团队由资深动画师、AI专家和软件工程师组成，紧密合作，共同推动动画行业的革新和进步。</p>

        </Card>
    );
}

export default Models;